<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>
<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'Howellcare Industries Sdn Bhd',
      titleTemplate: 'Howellcare Industries Sdn Bhd',
      htmlAttrs: { lang: 'en' },
      meta: [
        { property: 'og:site_name', content: 'Howellcare Industries Sdn Bhd'},
        { property: 'og:description', content: 'Howellcare Industries Sdn Bhd is a one-stop service provider for nitrile glove manufacturing. Delivering excellence in manufacturing international standard nitrile gloves for personal barrier protection.'},
        { property: 'og:image', content: 'https://howellcareindustries.com/howellcare.jpg'},
        { property: 'og:url', content: 'https://howellcareindustries.com'},
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { vmid:'keyword', name: 'keyword', content: 'Howellcare Industries, Howellcare Industries Sdn Bhd, Francis Ho Chia Yao, Nitrile Glove Manufacturing, Nitrile Glove Solution Provider, Nitrile Glove Manufacturing Line Suppplier, Nitrile Glove Manufacturing Line Machinery, Glove manufacturing equipment, Double former glove manufacturing line, South East Asia, Medical Exam Gloves, Food Grade Gloves, Accelerator-free Nitrile Gloves, Hong Seng Gloves Sdn Bhd, Howellcare, Howellcare turn-key contractor, Nitrile Glove turnkey contractor, Malaysia, Kedah Rubber City Nitrile Glove Manufacturing Plant, Hong Seng Gloves Manufacturing Plant, Bakar Arang, Kedah, Nitrile Gloves export, Nitrile gloves export USA, Nitrile gloves export European Market, FDA approved nitrile gloves supplier, medical examination nitrile gloves manufacturer Malaysia, medical examination nitrile gloves exporter, one-stop solution provider nitrile glove industry, nitrile glove manufacturing industry, NBR latex, Nitrile plant set-up, nitrile glove plant design, engineering, construction, commissioning, debugging, manufacturing, rubber glove products, peronal barrier protection, nitrile glove contractors, nitrile glove manufacturing licencing, MARGMA, CIDB Malaysia, Lembaga Getah Malaysia, LGM, Malaysian Rubber Glove Manufacturing Malaysia, innovative nitrile glove technology, industry standard medical examination gloves,nitrile glove manufacturing specialist, acquisition and refurbishment of synthetic glove production lines and related machinery, soft durable nitrile gloves, consistent performance, textured fingertips, allergy prevention gloves, 100% Thiuram-free formulation gloves, glove sample Malaysia, nitrile, glove, manufacturing, products, international' },
        { vmid:'description', name: 'description', content: 'Howellcare Industries Sdn Bhd is a one-stop service provider for nitrile glove manufacturing. Delivering excellence in manufacturing international standard nitrile gloves for personal barrier protection.' },
      ],
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>

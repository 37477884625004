<template>
  <div
    :class="classes"
    class="pt-2"
  >
    <base-avatar
      v-if="icon"
      :color="color"
      :dark="dark"
      :icon="icon"
      :outlined="outlined"
      :size="size"
      class="mb-3 align-self-start"
    />

    <div :class="horizontal && title && 'ml-6'">
      <base-title
        :title="location"
        class="text-uppercase overflow-y-auto secondary--text"
        space="3"
        mobileSize="text-subtitle-2"
      />
    </div>
    <base-divider
      v-if="chosen"
      color="third"
    />
  </div>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseAvatarCardPortfolio',

    mixins: [Heading],

    props: {
      align: {
        type: String,
        default: 'left',
      },
      color: String,
      dark: Boolean,
      horizontal: Boolean,
      icon: String,
      outlined: {
        type: Boolean,
        default: true,
      },
      space: {
        type: [Number, String],
        default: 8,
      },
      size: {
        type: [Number, String],
        default: 72,
      },
      text: String,
      title: String,
      flags: [],
      location: String,
      chosen: Boolean,
    },

    computed: {
      classes () {
        const classes = [
          `mb-${this.space}`,
        ]

        if (this.horizontal) {
          classes.push('d-flex')

          if (!this.$slots.default && !this.text) {
            classes.push('align-center')
          }
        }

        return classes
      },
    },
    methods: {
      fnMakeItBigger (event) {
        event.currentTarget.style.height = '100px'
      },
      fnMakeItSmaller (event) {
        event.currentTarget.style.height = '80px'
      },
    },
  }
</script>

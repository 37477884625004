<template>
  <v-img
    :src="src"
    :height="$vuetify.breakpoint.mdAndUp ? 600 : 300"
    class="white--text"
  >
    <v-container class="fill-height">
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        width="100%"
      >
        <div
          class="pa-4"
          min-height="100"
        >
          <base-title
            :title="title"
            class="text-uppercase text-center"
            size="text-h4"
            mobileSize="text-h5"
            color="secondary--text"
          />

          <base-body
            :text="!html ? truncatedText : undefined"
            :html="html"
            space="0"
          />
        </div>
        <div class="text-center">
          <v-btn
            v-if="readMore"
            class="font-weight-bold primary"
            text
            @click=fnGoToContact
          >
            Discover More
          </v-btn>
        </div>
        <v-divider
          v-if="divider"
          class="mt-6"
        />
      </v-responsive>
    </v-container>
  </v-img>
</template>

<script>
  export default {
    name: 'NewsCardProducts',

    props: {
      category: String,
      comments: [Number, String],
      date: String,
      divider: Boolean,
      html: String,
      icon: String,
      prominent: Boolean,
      readMore: Boolean,
      src: String,
      text: String,
      title: String,
      truncate: [Number, String],
    },

    computed: {
      truncatedText () {
        if (!this.text) return ''

        const truncate = Number(this.truncate)

        return this.text.length > truncate
          ? this.text.slice(0, truncate) + ' [...]'
          : this.text
      },
    },
    methods: {
      fnGoToContact () {
        if (this.title === 'Nitrile Gloves') {
          this.$router.push('#nitrile-gloves')
        } else {
          this.$router.push('#latex-gloves')
        }
      },
    },
  }
</script>

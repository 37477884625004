// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth',
      })
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'about',
          name: 'About Us',
          component: () => import('@/views/about/Index.vue'),
        },
        {
          path: 'csr',
          name: 'CSR',
          component: () => import('@/views/csr/Index.vue'),
        },
        {
          path: 'products',
          name: 'Products',
          component: () => import('@/views/products/Index.vue'),
        },
        {
          path: 'core',
          name: 'Core Business',
          component: () => import('@/views/core/Index.vue'),
        },
        {
          path: 'contact',
          name: 'Contact Us',
          component: () => import('@/views/contact-us/Index.vue'),
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },
  ],
})

export default router

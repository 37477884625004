<template>
  <v-theme-provider>
    <div>
      <template v-for="({ icon, text, title: t }, i) in info">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :outlined="false"
          :title="!dense ? t : undefined"
          color="transparent"
          horizontal
          space="0"
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <div
            v-html="text"
          />
        </base-avatar-card>
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContactNanotech',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
      countury: String,
    },

    data: () => ({
      info: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'Lot 833, Jalan Mustaffa Al-Bakri, 36000 Teluk Intan, Perak Darul Ridzuan, Malaysia',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '(+60) 5-621-3187',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'inquiry@howellcareindustries.com<br>marketing@howellcareindustries.com',
        },
      ],
    }),
  }
</script>
